import Image from 'next/image'
import Link from 'next/link'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { PresentationCard } from '../../../components/atoms/PresentationCard'
import {
  TypographyBody,
  TypographyFooter,
  TypographyHeader,
} from '../../../components/atoms/PresentationCard/PresentationCard.styled'
import { pathENUM } from '../../../registrators/registrators'
import {
  SBlockListContainer,
  SBodyWrapp,
  SHeader,
  SLink,
  SLinkNews,
} from './Body.styled'
import { InfoBlock } from './InfoBlock'
import {
  ShowAllContent,
  ShowAllContentNews,
  STitleWrapper,
} from './InfoBlock/InfoBlock.styled'
import { StatisticBlock } from './StatisticBlock/StatisticBlock'

export interface INewsBody {
  id: number
  image: string
  preview: string
  title: string
  tags?: string
}

export interface IProgramsBody {
  id: number
  short_description: string
  age_pupil: string
  kind_name: string
  name: string
}

export interface IStatistics {
  genius_quantity: number
  pupil_quantity: number
  unit_quantity: number
}

export interface IEventsBody {
  date_from: string
  id: number
  name: string
  age_pupil: string
}

export interface IBodyProps {
  newsData: INewsBody[]
  programData: IProgramsBody[]
  eventsData: IEventsBody[]
  statisticsData: IStatistics
}

const totalNewsCount = 3

export const Body: FC<IBodyProps> = ({
  newsData,
  programData,
  eventsData,
  statisticsData,
}) => {
  const upperCaseToString = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1)

  const programsContent = useMemo(
    () =>
      programData.map((program: IProgramsBody) => (
        <SLink key={program.id} href={`${pathENUM.programs}/${program.id}`}>
          <PresentationCard
            key={program.id}
            body={
              <TypographyBody>
                {upperCaseToString(program.short_description)}
              </TypographyBody>
            }
            header={
              <TypographyHeader>
                {upperCaseToString(program.name)}
              </TypographyHeader>
            }
            footer={
              <TypographyFooter>
                {program.age_pupil} лет · {program.kind_name}
              </TypographyFooter>
            }
          />
        </SLink>
      )),
    [programData],
  )

  const eventsContent = useMemo(
    () =>
      eventsData &&
      eventsData.map((event: IEventsBody) => (
        <SLink key={event.id} href={`${pathENUM.events}/${event.id}`}>
          <PresentationCard
            key={event.id}
            header={<SHeader>{upperCaseToString(event.name)}</SHeader>}
            footer={
              <TypographyFooter>
                {event.age_pupil} лет · {event.date_from}
              </TypographyFooter>
            }
          />
        </SLink>
      )),
    [eventsData],
  )

  const newsContent = useMemo(
    () =>
      newsData &&
      newsData.slice(0, totalNewsCount).map((news: INewsBody) => (
        <SLinkNews key={news.id} href={`${pathENUM.news}/${news.id}`}>
          <PresentationCard
            key={news.id}
            header={
              <SHeader>
                {ReactHtmlParser(upperCaseToString(news.title))}
              </SHeader>
            }
            footer={
              <TypographyFooter>
                {ReactHtmlParser(
                  upperCaseToString(news?.tags || 'Образование'),
                )}
              </TypographyFooter>
            }
            body={
              <TypographyBody>
                <Image
                  src={`${process.env.NEXT_PUBLIC_MEDIA_URL}${news.image}`}
                  alt=""
                  width={400}
                  height={180}
                  placeholder="blur"
                  blurDataURL={`${process.env.NEXT_PUBLIC_MEDIA_URL}`}
                />
              </TypographyBody>
            }
          />
        </SLinkNews>
      )),
    [newsData],
  )

  const statisticsContents = useMemo(() => {
    if (statisticsData) {
      return <StatisticBlock statisticsData={statisticsData} />
    }
    return null
  }, [statisticsData])
  const pathEvents = useMemo(
    () => (
      <ShowAllContent>
        <Link
          href={`${pathENUM.events}?page=1&per_page=9&all_dates=false&only_future_dates=true&sort_by=%5B"date_from"%5D`}>
          Показать все
        </Link>
      </ShowAllContent>
    ),
    [],
  )
  const pathNews = useMemo(
    () => (
      <ShowAllContentNews>
        <Link href={`${pathENUM.news}?page=1&per_page=9`}>Показать все</Link>
      </ShowAllContentNews>
    ),
    [],
  )

  const pathPrograms = useMemo(
    () => (
      <ShowAllContent>
        <Link href={`${pathENUM.programs}?page=1&per_page=9`}>
          Показать все
        </Link>
      </ShowAllContent>
    ),
    [],
  )

  return (
    <SBodyWrapp>
      <SBlockListContainer>
        <InfoBlock
          path={pathNews}
          title={<STitleWrapper className="color-white">Новости</STitleWrapper>}
          content={newsContent}
        />
        <InfoBlock
          path={pathPrograms}
          title={<STitleWrapper>Программы</STitleWrapper>}
          content={programsContent}
        />
        <InfoBlock
          path={pathEvents}
          title={<STitleWrapper>Мероприятия</STitleWrapper>}
          content={eventsContent}
        />
        <InfoBlock
          path=""
          title={<STitleWrapper>Статистика</STitleWrapper>}
          content={statisticsContents}
        />
      </SBlockListContainer>
    </SBodyWrapp>
  )
}
