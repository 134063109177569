import type { FC, ReactNode } from 'react'
import React from 'react'

import {
  InfoBlockWrapp,
  SCardList,
  SHeaderWrapper,
  STitle,
} from './InfoBlock.styled'

export interface IInfoBlockProps {
  content: ReactNode
  title: ReactNode
  path?: ReactNode
}

const InfoBlock: FC<IInfoBlockProps> = ({ content, title, path }) => {
  if (content) {
    return (
      <InfoBlockWrapp>
        <SHeaderWrapper>
          <STitle>{title}</STitle>
          {path}
        </SHeaderWrapper>
        <SCardList>{content}</SCardList>
      </InfoBlockWrapp>
    )
  }
  return (
    <InfoBlockWrapp>
      <STitle>{title}</STitle>
      <SCardList>Кажется данные еще в пути...</SCardList>
    </InfoBlockWrapp>
  )
}

export default React.memo(InfoBlock)
