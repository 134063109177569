import type { FC } from 'react'

import { mainPageAPI, statisticsAPI } from '../axios/axios'
import HomePage from '../home'
import type {
  IEventsBody,
  INewsBody,
  IProgramsBody,
  IStatistics,
} from '../home/HomePageContent/Body/Body'

interface IHomeProps {
  programsData: IProgramsBody[]
  newsData: INewsBody[]
  eventsData: IEventsBody[]
  statisticsData: IStatistics
}

const Home: FC<IHomeProps> = ({
  programsData,
  eventsData,
  newsData,
  statisticsData,
}) => (
  <HomePage
    statisticsData={statisticsData}
    programData={programsData}
    eventsData={eventsData}
    newsData={newsData}
  />
)

export default Home

export async function getServerSideProps() {
  try {
    const programs = await mainPageAPI.getPrograms()
    const events = await mainPageAPI.getEvents()
    const news = await mainPageAPI.getNews()
    const statistics = await statisticsAPI.statistics()

    const programsData = programs.data.data ?? []
    const eventsData = events.data.data ?? []
    const newsData = news.data.data ?? []
    const statisticsData = statistics.data ?? null

    return {
      props: {
        programsData,
        eventsData,
        newsData,
        statisticsData,
      },
    }
  } catch (e) {
    return {
      props: {
        programsData: [],
        eventsData: [],
        newsData: [],
        statisticsData: [],
      },
    }
  }
}
