import Head from 'next/head'
import type { FC } from 'react'
import React from 'react'
import { TITLE_TEXT } from 'textConsts'

import type {
  IEventsBody,
  INewsBody,
  IProgramsBody,
  IStatistics,
} from './HomePageContent/Body/Body'
import { Body } from './HomePageContent/Body/Body'
import { MainMenu } from './HomePageContent/MainMenu/MainMenu'
import { SHomePageLayout } from './styles/HomePage.styled'

export interface IHomePageProps {
  newsData: INewsBody[]
  programData: IProgramsBody[]
  eventsData: IEventsBody[]
  statisticsData: IStatistics
}

export const HomePage: FC<IHomePageProps> = ({
  newsData,
  programData,
  eventsData,
  statisticsData,
}) => (
  <SHomePageLayout>
    <Head>
      <title>{TITLE_TEXT}</title>
      <meta name="description" content={TITLE_TEXT} />
    </Head>

    <MainMenu />
    <Body
      programData={programData}
      eventsData={eventsData}
      newsData={newsData}
      statisticsData={statisticsData}
    />
  </SHomePageLayout>
)
