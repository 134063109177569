import React from 'react'

import Icon from '@ant-design/icons'

const HomeIconSVG = () => (
  <svg
    width="54"
    height="46"
    viewBox="0 0 54 46"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M27 7.17333L40.3333 19.1733V40H35V24H19V40H13.6666V19.1733L27 7.17333ZM27 0L0.333313 24H8.33331V45.3333H24.3333V29.3333H29.6666V45.3333H45.6666V24H53.6666" />
  </svg>
)

export const HomeIcon = (props: any) => (
  <Icon component={HomeIconSVG} {...props} />
)
