import Link from 'next/link'
import styled from 'styled-components'

import { styleMixins } from '../../../style'

const { defaultTextStyle, defaultTitleStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SBodyWrapp = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  padding-bottom: ${getSpacing(10)};
  background-color: ${(p) => p.theme.colors.grey_100};

  @media ${(p) => p.theme.breakpoints.mobile} {
    padding-bottom: ${getSpacing(5)};
  }
`

export const SBlockListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(10)};
  margin-top: ${getSpacing(-15)};

  @media ${(p) => p.theme.breakpoints.mobile} {
    padding: ${getSpacing([0, 3, 0, 3])};
    gap: ${getSpacing(5)};
    align-items: center;

    .cardMainContent {
      display: grid;
      grid-template-columns: 1fr;
      gap: ${getSpacing(2)};
      width: 100%;
    }
  }
`

export const SLink = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${(p) => p.theme.colors.grey_600};
  ${defaultTextStyle(6)};
  min-width: 280px;
  max-height: 250px;
  max-width: 350px;
`

export const SLinkNews = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${(p) => p.theme.colors.grey_600};
  ${defaultTextStyle(6)};
  min-width: 280px;
  max-height: 400px;
`

export const SHeader = styled.div`
  display: -webkit-box;
  height: max-content;
  color: ${(p) => p.theme.colors.grey_600};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  ${defaultTitleStyle(7)};
`

export const STitle = styled.div`
  color: ${(p) => p.theme.colors.grey_600};
`

export const SStatistics = styled.div`
  display: contents;

  @media ${(p) => p.theme.breakpoints.mobile} {
    display: grid;
    gap: ${getSpacing(2)};
    width: 100%;
  }
`
