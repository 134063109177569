import styled from 'styled-components'

import { styleMixins } from '../../../../style'
import {
  defaultTextStyle,
  defaultTitleStyle,
} from '../../../../style/styleMixins/text'

const { getSpacing } = styleMixins.offset

export const ShowAllContentNews = styled.div`
  display: flex;
  a {
    text-decoration: none;
    color: ${(p) => p.theme.colors.grey_000};
  }
  @media ${(p) => p.theme.breakpoints.mobile} {
    margin-bottom: ${getSpacing(0.75)};
  }
`
export const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(p) => p.theme.breakpoints.desktop} {
    padding-left: ${getSpacing(3)};
    padding-right: ${getSpacing(3)};
  }
  @media ${(p) => p.theme.breakpoints.mobile} {
    padding-left: 0;
    padding-right: 0;
    gap: ${getSpacing(1)};
    align-items: baseline;
  }
`

export const ShowAllContent = styled.div`
  display: flex;

  a {
    text-decoration: none;
    color: ${(p) => p.theme.colors.blue_500};
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin-top: ${getSpacing(1.75)};
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    margin: ${getSpacing([0, 0, 0, 4])};
    ${defaultTextStyle(1)}
  }
`
export const InfoBlockWrapp = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(5)};

  @media ${(p) => p.theme.breakpoints.mobile} {
    flex-direction: column;
    gap: ${getSpacing(3)};
    margin-bottom: 0;
    gap: ${getSpacing(3)};
    min-width: 300px;
  }
`

export const SCardList = styled.div`
  display: grid;
  justify-content: center;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1120px;
  gap: ${getSpacing(4)};

  @media ${(p) => p.theme.breakpoints.desktop} {
    width: 100%;
    display: flex;

    .bodyCard {
      max-width: 352px;
    }
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    gap: ${getSpacing(2)};
    width: 100%;
    max-width: 300px;
    padding: 0;
    .bodyCard {
      max-width: 284px;
    }
  }
`
export const STitleWrapper = styled.div`
  display: flex;
  ${defaultTitleStyle(2)};
  color: ${(p) => p.theme.colors.grey_600};

  &.color-white {
    color: ${(p) => p.theme.colors.grey_000};
  }
  @media ${(p) => p.theme.breakpoints.tablet} {
    margin-top: ${getSpacing(2)};
    padding-left: 5%;
    padding-right: 5%;
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    padding-left: 0;
    padding-right: 0;
    ${defaultTitleStyle(4)};
  }
`

export const STitle = styled.div`
  display: flex;
  ${defaultTitleStyle(2)};
  color: ${(p) => p.theme.colors.grey_000};
`
