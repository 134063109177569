import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import { useCallback, useMemo, useState } from 'react'

import { redirectToGosuslugi } from '../../../axios/utils'
import { Modal } from '../../../components/atoms/Modal'
import { pathENUM } from '../../../registrators/registrators'
import { generalTheme } from '../../../style'
import { Feedback } from './components/Feedback/Feedback'
import { InstructionsForOrganization } from './components/ForOrganization/InstructionsForOrganization'
import { ForParent } from './components/ForParent/ForParent'
import { radioItemENUM } from './MainMenu.model'
import { SButtonGroup, SRadio } from './MainMenu.styled'

type ModalContentType = Record<string, ReactNode>

export const RadioGroup = () => {
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedContent, setSelectedContent] = useState<radioItemENUM>(
    radioItemENUM.instructionsForOrganizations || radioItemENUM.feedback,
  )

  const handleSetOpen = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [])

  const modalContent: ModalContentType = useMemo(
    () => ({
      [radioItemENUM.instructionsForParents]: <ForParent />,
      [radioItemENUM.feedback]: <Feedback />,
      [radioItemENUM.instructionsForOrganizations]: (
        <InstructionsForOrganization />
      ),
    }),
    [],
  )

  const openModalFC = useCallback(
    (value: radioItemENUM) => {
      setSelectedContent(value)
      handleSetOpen()
    },
    [handleSetOpen],
  )

  const redirectFC = useCallback(
    (url: pathENUM) => {
      router.push({
        pathname: url,
        query: {
          ...router.query,
          page: 1,
          per_page: 9,
        },
      })
    },
    [router],
  )

  const redirectEvents = useCallback(() => {
    router.push({
      pathname: pathENUM.events,
      query:
        '&all_dates=false&only_future_dates=true&sort_by=%5B"date_from"%5D',
    })
  }, [router])

  const radioGroup = useMemo(
    () => (
      <SButtonGroup>
        <SRadio onClick={() => openModalFC(radioItemENUM.feedback)}>
          Обратная связь
        </SRadio>
        <SRadio onClick={() => redirectFC(pathENUM.programs)}>
          Запись на обучение
        </SRadio>
        <SRadio onClick={redirectEvents}>Запись на мероприятие</SRadio>
        <SRadio onClick={() => redirectFC(pathENUM.organizations)}>
          Просмотр организаций
        </SRadio>
        <SRadio onClick={() => redirectToGosuslugi()}>ГОСУСЛУГИ</SRadio>
        <SRadio
          onClick={() =>
            openModalFC(radioItemENUM.instructionsForOrganizations)
          }>
          Инструкции для организаций
        </SRadio>
        <SRadio
          onClick={() => openModalFC(radioItemENUM.instructionsForParents)}>
          Инструкции для родителей
        </SRadio>
      </SButtonGroup>
    ),
    [openModalFC, redirectEvents, redirectFC],
  )

  return (
    <>
      {radioGroup}
      <Modal
        modalContentMaxWidth={generalTheme.maxWidthModal.default}
        onClose={handleSetOpen}
        onCancel={handleSetOpen}
        open={isOpen}>
        {modalContent[selectedContent]}
      </Modal>
    </>
  )
}
