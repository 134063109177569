import type { FC } from 'react'
import React, { useMemo } from 'react'

import { HomeIcon } from '../../../../assets/img/logo/Home'
import { PeopleProfileIcon } from '../../../../assets/img/logo/PeopleProfile'
import { Statistics } from '../../../../components/atoms'
import { SStatistics } from '../Body.styled'

interface IStatisticsData {
  genius_quantity: number
  pupil_quantity: number
  unit_quantity: number
}

interface StatisticsBlockProps {
  statisticsData: IStatisticsData
}

export const StatisticBlock: FC<StatisticsBlockProps> = ({
  statisticsData,
}) => {
  const {
    pupil_quantity: pupilQuantity,
    unit_quantity: unitQuantity,
    genius_quantity: geniusQuantity,
  } = statisticsData
  const pseudoOdo = 471
  const statisticsContent = useMemo(
    () => (
      <>
        <Statistics
          body="Учащихся в ОДО"
          colorNow="#30AF1D"
          icon={<PeopleProfileIcon />}>
          {pupilQuantity}
        </Statistics>
        <Statistics body="ОДО" colorNow="#C53394" icon={<HomeIcon />}>
          {unitQuantity || pseudoOdo}
        </Statistics>
      </>
    ),
    [geniusQuantity, pupilQuantity, unitQuantity],
  )

  return <SStatistics>{statisticsContent}</SStatistics>
}
