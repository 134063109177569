import { AutoComplete } from 'antd'
import type { ItemType } from 'components/Filters/ApplyFilters.model'
import { useRouter } from 'next/router'
import {
  DEFAULT_FIRST_PAGE_PAGINATOR,
  DEFAULT_OBJECTS_PER_PAGE,
} from 'numberConsts'
import React, { useCallback, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { filtersAPI } from '../../../axios/axios'
import { SLink } from '../../../MainContainer/PagesList/PagesList.styled'
import { FilterWrapper } from '../../../pages/map/style/FilterWrapper.styled'
import { FiltersMainMenu } from './components/Filters/FiltersMainMenu'
import {
  SFilterContent,
  SFilterWrapp,
  SInput,
  SMapPosition,
  STitle,
  STitleWrapp,
  SWrappSpaceBlock,
} from './MainMenu.styled'
import { RadioGroup } from './RadioGroup'

interface IOptionBySearch {
  obj_url: string
  name: string
}

export const MainMenu = () => {
  const abortControllerRef = useRef<null | AbortController>(null)
  const [options, setOptions] = useState<ItemType[] | undefined>()
  const [selectedSearchValue, setSelectedSearchValue] = useState<string>('')

  const router = useRouter()

  const onSearch = useCallback(async (value: string) => {
    if (value) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
        abortControllerRef.current = null
      }
      abortControllerRef.current = new AbortController()
      setSelectedSearchValue(value)

      try {
        const res = await filtersAPI.mainFilter(value, {
          signal: abortControllerRef.current?.signal,
        })

        const { result } = res.data

        if (result.length) {
          const formattedData = result.map((option: IOptionBySearch) => ({
            value: option.obj_url,
            label: ReactHtmlParser(option.name),
          }))
          setOptions(formattedData)
        } else {
          setOptions([])
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      setOptions([])
      setSelectedSearchValue('')
    }
  }, [])

  const onSelect = useCallback(
    (value: string) => {
      if (value.includes('posts')) {
        // забираем id новости, с бэка приходит '/posts/${id}' а у нас роут '/news/${id}'
        const idNewsBySplitString = 2
        const idNews = value.split('/')[idNewsBySplitString]
        router.push(`/news/${idNews}`)
      } else router.push(value)
    },
    [router],
  )

  return (
    <SFilterWrapp>
      <SFilterContent>
        <STitleWrapp>
          <STitle>Что вас интересует?</STitle>
          <SMapPosition>
            <SLink href="/map">На карте</SLink>
          </SMapPosition>
        </STitleWrapp>
        <SWrappSpaceBlock block>
          <SInput>
            <AutoComplete
              showSearch
              placeholder="Программы, мероприятия или организации"
              style={{ width: '100%' }}
              options={options}
              onSelect={onSelect}
              onSearch={onSearch}
              value={selectedSearchValue}
            />
            <FilterWrapper>
              <FiltersMainMenu
                initialQuery={{
                  page: DEFAULT_FIRST_PAGE_PAGINATOR.toString(),
                  per_page: DEFAULT_OBJECTS_PER_PAGE.toString(),
                }}
              />
            </FilterWrapper>
          </SInput>
        </SWrappSpaceBlock>
        <RadioGroup />
      </SFilterContent>
    </SFilterWrapp>
  )
}
