import React from 'react'

import Icon from '@ant-design/icons'

const PeopleIconSvg = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M22 5.73329C22.7354 5.73329 23.4636 5.87814 24.143 6.15957C24.8225 6.44099 25.4398 6.85349 25.9598 7.37349C26.4798 7.8935 26.8923 8.51084 27.1737 9.19027C27.4552 9.86969 27.6 10.5979 27.6 11.3333C27.6 12.0687 27.4552 12.7969 27.1737 13.4763C26.8923 14.1557 26.4798 14.7731 25.9598 15.2931C25.4398 15.8131 24.8225 16.2256 24.143 16.507C23.4636 16.7884 22.7354 16.9333 22 16.9333C20.5148 16.9333 19.0904 16.3433 18.0402 15.2931C16.99 14.2429 16.4 12.8185 16.4 11.3333C16.4 9.84808 16.99 8.4237 18.0402 7.37349C19.0904 6.32329 20.5148 5.73329 22 5.73329ZM22 29.7333C29.92 29.7333 38.2667 33.6266 38.2667 35.3333V38.2666H5.73333V35.3333C5.73333 33.6266 14.08 29.7333 22 29.7333ZM22 0.666626C16.1067 0.666626 11.3333 5.43996 11.3333 11.3333C11.3333 17.2266 16.1067 22 22 22C27.8933 22 32.6667 17.2266 32.6667 11.3333C32.6667 5.43996 27.8933 0.666626 22 0.666626ZM22 24.6666C14.88 24.6666 0.666664 28.24 0.666664 35.3333V43.3333H43.3333V35.3333C43.3333 28.24 29.12 24.6666 22 24.6666Z" />
  </svg>
)

export const PeopleProfileIcon = (props: any) => (
  <Icon component={PeopleIconSvg} {...props} />
)
